import React from 'react'
import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { collection, doc, updateDoc, onSnapshot, query, where } from "firebase/firestore";
import { db } from '../firebase-config'
import imgCancel from '../images/cancel.png'
import imgSave from '../images/save.png'

const UpdateSettings = () => {
    const navigate = useNavigate()
    // const [firstName, setFirstName] = useState("")
    // const [vehicleStartNumber, setVehicleStartNumber] = useState("")
    const [settingID, setSettingID] = useState("")
    const [settings, setSettings] = useState("")
    const { user } = UserAuth()
    const settingCollRef = collection(db, "settings")


    useEffect(()  => {
    
        const unsub = onSnapshot(query(settingCollRef, where("useremail", "==", `${user?.email}`)), (snapShot) => {
            // if (snapShot.docs.length > 0) {
            //     navigate('/updatesettings')
            // } else {
                try {
                
                    let list = [];
                    snapShot.docs.forEach(doc=>{
                        list.push({id:doc.id, ...doc.data()});
                        setSettingID(doc.id)
                        document.getElementById("newFirstName").value = doc.data().firstName
                        document.getElementById("newVehicleStartNumber").value = doc.data().vehicleStartNumber
                        document.getElementById("newNextVehicleNumber").value = doc.data().nextVehicleNumber
                    });
                    setSettings(list);
                    
                    } catch (error) {
                    console.log(error);
                    }
            // }
          });
      
          return () => {
            unsub();
          }
    
    }, [])


    const updateSettings = async (event) => {
        event.preventDefault();
  
        var newFirstName = document.getElementById("newFirstName").value
        var newVehicleStartNumber = document.getElementById("newVehicleStartNumber").value
        var newNextVehicleNumber = document.getElementById("newNextVehicleNumber").value
        
        const updatedChanges = {
          firstName: newFirstName,
          vehicleStartNumber: newVehicleStartNumber,
          nextVehicleNumber: newNextVehicleNumber,
        }
        
        const settingsRef = doc(settingCollRef, settingID)
        await updateDoc(settingsRef, updatedChanges)
        navigate("/vehicles")
      }
    
    
    
    
    
    return (
        <Container className='panel col-sm-12 col-md-8 col-lg-6 mx-auto'>
            <Row className='mx-auto'>
                <Col className=''>
                    <h1>Update Settings</h1>
                    <hr />
                    <form className='d-flex flex-column'>
                        <label htmlFor='newUserEmail'>User Email</label>
                        <input className='m-1 p-2' id='useremail' value={user?.email} readOnly />

                        <label htmlFor='newFirstName'>First Name</label>
                        <input className='m-1 p-2' id='newFirstName' />
                        
                        <label htmlFor='newVehicleStartNumber'>Starting Vehicle Number</label>
                        <input className='m-1 p-2' id='newVehicleStartNumber' />
                        
                        <label htmlFor='newNextVehicleNumber'>Next Vehicle Number</label>
                        <input className='m-1 p-2' id='newNextVehicleNumber' readOnly />
                    </form>
                    
                    <div className='d-flex justify-content-end mt-3'>
                        <a onClick={() => navigate("/vehicles")}><img className="icons" src={imgCancel} /></a>
                        <a onClick={updateSettings}><img className="icons ms-3" src={imgSave} /></a>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default UpdateSettings