import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Image } from 'react-bootstrap'
import { UserAuth } from '../context/AuthContext'
// import swLogo from '../images/sidewayslogo_mg.png'
import logo from '../images/mmg_logo.png'

const Header = () => {
  const { userLogout, user } = UserAuth()
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      await userLogout()
      console.log("You are now logged out")
      navigate("/");
    } catch (error) {
      console.log(error.message)
    }
  }


  return (
    <div>
    {/* <div className='specialMessage'><h1></h1></div> */}
    <div className=''>
      
        <div className='d-flex justify-content-between align-items-center p-3 header'>
          <div className=''>
              {/* <a onClick={() => navigate("/vehicles")}><h1 style={{fontFamily: "Oswald"}}>My Garage</h1></a> */}
              {/* <a onClick={() => navigate("/vehicles")}><h1 style={{fontFamily: "Oswald"}}>My Moto Garage</h1></a> */}
              <a onClick={() => navigate("/vehicles")}><Image fluid className='headerLogo logo rounded m-2' src={logo} /></a>
          </div>

          <div className='d-flex flex-column align-items-end'>
              <h5 className='headerFont'>User: {user?.email}</h5>
              <Button style={{fontWeight: "bold"}} className='xm-1 xp-1 ms-4' onClick={handleLogout}>Logout</Button>
          </div>

        </div>
      
    </div>
    </div>
  )
}

export default Header