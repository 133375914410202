import React from 'react'
import logo from '../images/sidewayslogo_mg.png'
import { Image } from 'react-bootstrap'

const Footer = () => {
  return (
    <div className='footer xd-flex xjustify-content-center xalign-items-center'>

        <div className='footerAcknowledgements xfooterBox xborder p-3 text-end w-100'>
          {/* <h4 className='text-start'>Acknowledgements</h4> */}
          {/* <hr /> */}
          <p className='m-0' style={{fontSize: "24px", fontFamily: "Oswald"}}>My Auto Garage</p>
          <p className='m-0' style={{fontSize: "14px", fontFamily: "Aleo"}}>by Barbone <strong><span style={{color: "red", fontSize: "14px", fontFamily: "Urbanist"}}>IT</span></strong> Solutions</p>
          <p className='m-0'>PO Box 730862</p>
          <p className='m-0'>Ormond Beach, FL 32173-0862</p>
          <p className='m-0'><a href='mailto://joe@barboneit.com'>Joe@BarboneITSolutions.com</a></p>
          <p className='mt-3 m-0' style={{fontWeight: "bold"}}>Acknowledgements</p>
          <p className='m-0'><a href="https://www.flaticon.com/free-icons/modify" title="modify icons">Modify icons created by Freepik - Flaticon</a></p>
          <p className='m-0'><a href="https://www.flaticon.com/free-icons/exit" title="exit icons">Exit icons created by Freepik - Flaticon</a></p>
          <p className='m-0'><a href="https://www.flaticon.com/free-icons/forbidden" title="forbidden icons">Forbidden icons created by Becris - Flaticon</a></p>
          <p className='m-0'><a href="https://www.flaticon.com/free-icons/save" title="save icons">Save icons created by Those Icons - Flaticon</a></p>
          <p className='m-0'><a href="https://www.flaticon.com/free-icons/delete" title="delete icons">Delete icons created by IYAHICON - Flaticon</a></p>
          <p className='m-0'>< a href="https://www.flaticon.com/free-icons/question" title="question icons">Question icons created by Freepik - Flaticon</a></p>
          <p className='' style={{fontSize: "12px"}}>&copy;2023. All rights reserved.</p>
        </div>
        
    </div>
  )
}

export default Footer