import React from 'react'
import { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import { collection, orderBy, where, query, onSnapshot } from "firebase/firestore"
import { db } from "../firebase-config"
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import { UserAuth } from '../context/AuthContext'
import imgView from '../images/view.png'
import imgAdd from '../images/add.png'
import imgSettings from '../images/settings.png'
import imgHelp from '../images/help.png'



const VehicleList = () => {
    const vehicleCollRef = collection(db, "vehicleinfo");
    const [vehicles, setVehicles] = useState([]);
    const [selectAll, setSelectAll] = useState("")
    const navigate = useNavigate();
    const { user } = UserAuth()
    const settingCollRef = collection(db, "settings")
    const [settings, setSettings] = useState("")
    
    useEffect(() => {
     
        const unsub = onSnapshot(query(vehicleCollRef, orderBy("vID"), where("active", "==", "Y"), where("useremail", "==", `${user?.email}`)), (snapShot) => {
          try {
            let list = [];
            snapShot.docs.forEach(doc=>{
              list.push({id:doc.id, ...doc.data()});
            });
            setVehicles(list);
          } catch (error) {
            console.log(error);
          }
        });
    
        return () => {
          unsub();
        }
      
        
    }, [user?.email]);
    


    const loadAllRecords = () => {
        const unsub = onSnapshot(query(vehicleCollRef, orderBy("vID"), where("useremail", "==", `${user?.email}`)), (snapShot) => {
            try {
              let list = [];
              snapShot.docs.forEach(doc=>{
                list.push({id:doc.id, ...doc.data()});
              });
              setVehicles(list);
            } catch (error) {
              console.log(error);
            }
          });
      
          return () => {
            unsub();
          }
    }
    

    return (
        <div className=''>
            <Header />

            <Container className='panel mx-auto'>
                <Row>
                    <Col>
                        <div className='d-flex justify-content-between'>
                            <h1>Vehicle List</h1>
                            <div className='d-flex'>
                              <div className='d-flex flex-column align-items-center'>
                                <a onClick={() => loadAllRecords()}><img className="icons" src={imgView} /></a>
                                <p>view all</p>
                              </div>
                              <div className='mx-3 d-flex flex-column align-items-center'>
                                <a onClick={() => navigate("/addvehicle")}><img className="icons" src={imgAdd} /></a>
                                <p>new vehicle</p>
                              </div>
                            </div>
                        </div>
                        <hr />
                        <div className='d-flex flex-wrap justify-content-center'>
                          {vehicles.map((vehicle) => {
                              // alert(vehicle.picID)
                              return(
                                  <div>
                                      <Card className='m-1 card'>
                                          {/* <a onClick={() => navigate("/" + vehicle.id)}><Card.Img className='cardImg' variant="top" src={'/images/'+ `${user.email}` + '/' + vehicle.vID + ".jpg"} /></a> */}
                                          <a onClick={() => navigate("/" + vehicle.id)}><Card.Img style={{height: "250px"}} className='cardImg' variant="top" src={vehicle?.picID} /></a>
                                          <Card.Body>
                                              <Card.Title className='cardTitle'>{vehicle.year} {vehicle.make} {vehicle.model}</Card.Title>
                                              <Card.Text>
                                                  
                                              </Card.Text>
                                              {/* <hr /> */}
                                              {/* <div className='text-end'> */}
                                                  {/* <a onClick={() => navigate("/addservicerecord/" + vehicle.vID)}><img className="icons" src={imgAdd} /></a> */}
                                                  {/* <p>add service record</p> */}
                                              {/* </div> */}
                                          </Card.Body>
                                      </Card>
                                  </div>
                              )
                              
                          })} {/* end of vehicles.map */}     
                        </div>
                        
                        <hr />
                        <div className='d-flex justify-content-between'>
                          <a onClick={() => navigate('/settings')}><img style={{width: "32px"}} className="icons" src={imgSettings} /></a>
                          <a onClick={() => navigate('/help')}><img style={{width: "32px"}} className="icons" src={imgHelp} /></a>
                        </div>
                        
                    </Col>
                </Row>
                
            </Container>

            <Footer />
        </div>
    )
}

export default VehicleList