import { createContext, useContext, useState, useEffect } from "react";

import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from 'firebase/auth'

import { auth } from "../firebase-config";


const UserContext = createContext()

export const AuthContextProvider = ({children}) => {

    const [user, setUser] = useState({}) 

    const userSignup = (signupEmail, signupPassword) => {
        return createUserWithEmailAndPassword(auth, signupEmail, signupPassword)
    }

    const userLogin = (loginEmail, loginPassword) => {
        return signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    }

    const userLogout = () => {
        return signOut(auth)
    }


    useEffect(() => {

        const unsub = onAuthStateChanged(auth, (currentUser) => {
            // console.log(currentUser)
            setUser(currentUser)
        })
        
        return () => {
            unsub();
        }

    }, [])

    return (
        <UserContext.Provider value={{ userSignup, userLogin, userLogout, user  }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}