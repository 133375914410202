import React from 'react'
import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { collection, addDoc, onSnapshot, query, where, getCountFromServer } from "firebase/firestore";
import { db } from '../firebase-config'
import imgCancel from '../images/cancel.png'
import imgSave from '../images/save.png'

const Settings = () => {
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState("")
    const [vehicleStartNumber, setVehicleStartNumber] = useState("")
    const [settings, setSettings] = useState("")
    const { user } = UserAuth()
    const settingCollRef = collection(db, "settings")

    useEffect(()  => {
    
        const unsub = onSnapshot(query(settingCollRef, where("useremail", "==", `${user?.email}`)), (snapShot) => {
            if (snapShot.docs.length > 0) {
                navigate('/updatesettings')
            } else {
                try {
                
                    let list = [];
                    snapShot.docs.forEach(doc=>{
                        list.push({id:doc.id, ...doc.data()});
                    });
                    setSettings(list);
                    
                    } catch (error) {
                    console.log(error);
                    }
            }
          });
      
          return () => {
            unsub();
          }
    
    }, [])
    

    const saveSettings = async (event) => {
        event.preventDefault()
    
        const settings = {
            useremail: user.email,
            firstName: firstName,
            vehicleStartNumber: Number(vehicleStartNumber),
            nextVehicleNumber: Number(vehicleStartNumber) + 1,
        }
    
        await addDoc(settingCollRef, settings)
        navigate("/vehicles")
    }


    return (
        <Container className='panel col-sm-12 col-md-8 col-lg-6 mx-auto'>
            <Row className='mx-auto'>
                <Col className=''>
                    <h1>Settings</h1>
                    <hr />
                    <form className='d-flex flex-column'>
                        <label htmlFor='useremail'>User Email</label>
                        <input className='m-1 p-2' id='useremail' value={user?.email} readOnly />

                        <label htmlFor='firstName'>First Name</label>
                        <input className='m-1 p-2' id='firstName' onChange={(event) => setFirstName(event.target.value)} />
                        
                        <label htmlFor='vehicleStartNumber'>Starting Vehicle Number</label>
                        <input className='m-1 p-2' id='vehicleStartNumber' onChange={(event) => setVehicleStartNumber(event.target.value)} />
                        
                        <label htmlFor='nextVehicleNumber'>Next Vehicle Number</label>
                        <input className='m-1 p-2' id='nextVehicleNumber' readOnly />
                    </form>
                    
                    <div className='d-flex justify-content-end mt-3'>
                        <a onClick={() => navigate("/vehicles")}><img className="icons" src={imgCancel} /></a>
                        <a onClick={saveSettings}><img className="icons ms-3" src={imgSave} /></a>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Settings