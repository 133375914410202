import React from 'react'
import { useState, useEffect } from 'react'
// import logo from '../images/logo_mg.jpg'
import logo from '../images/mmg_logo.png'
import jeep from '../images/jeep.png'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Image } from 'react-bootstrap'
import { UserAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import Header from './Header'
import Footer from './Footer'
 

const LandingPage = () => {

  const { userLogin } = UserAuth()
  const { user } = UserAuth()
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const navigate = useNavigate();

  

  useEffect(()  => {
    if (user) {
      navigate('/vehicles')
    }     
  }, [user?.email])


  const handleSubmit = async (event) => {
    event.preventDefault()
    
    try {
      // userLogin called from AuthContext
      userLogin(loginEmail, loginPassword)
      navigate('/vehicles')
    } catch (error) {
      alert(error.message);
    }
  }


  return (

    <div className='landingPage d-flex justfy-content-center align-items-center m-1'>
      <Container className='panel xbg col-sm-12 col-md-8 col-lg-6 mx-auto p-5'>
          
          <Row className='d-flex mobileLayout xflex-column xalign-items-center'>
        
              <Col className='d-flex justify-content-center align-items-center'>
                  <Image fluid className='logo rounded m-2' src={logo} />
                  <div>
                  {/* <h1 style={{fontWeight: "bold", color: "#899499", xtextAlign: "right"}} className='m-0'>My Moto Garage</h1>     */}
                  {/* <h1 style={{fontWeight: "bold", color: "black"}} className='m-0'>My Moto Garage</h1> */}
                  {/* <h6 style={{fontStyle: "italic", fontWeight: "bold", color: "#899499", textAlign: "right"}}>by Barbone IT Solutions, LLC</h6> */}
                  </div>
              </Col>

              <Col className='d-flex flex-column justify-content-center'>
                  
                  {/* <h1 style={{fontFamily: "Oswald", fontWeight: "bold", color: "#4EABBD", textAlign: "right"}}> */}
                  <h1 style={{fontFamily: "Oswald", fontWeight: "bold", color: "black", textAlign: "right"}}>
                      STILL KEEPING PAPER RECORDS?
                  </h1>
                  
                  <h3 style={{textAlign: "right", color: "#818589", fontWeight: "bold"}}>
                      Start using My Auto Garage and leave the trees alone! Faster, more accurate and easier to use, My Auto Garage provides everything you need, right at your fingertips!
                  </h3>
                  
                  <hr />
                  <div className='d-flex justify-content-end'>
                    <a onClick={() => navigate('/signup')}><button className='btn btn-primary'>Signup</button></a>
                    <a onClick={() => navigate('/login')}><button className='btn btn-primary ms-3'>Login</button></a>
                  </div>
                  {/* <p style={{color: "black"}} className='text-end mt-3'>Already have an account?<a onClick={() => navigate('/login')}> Login.</a></p> */}
                  {/* <h1 style={{fontWeight: "bold", color: "#899499", textAlign: "right"}} className='mt-5 m-0'>My Moto Garage</h1>     */}
                  {/* <h6 style={{fontStyle: "italic", fontWeight: "bold", color: "#899499", textAlign: "right"}}>by Barbone IT Solutions, LLC</h6> */}
              </Col>
              
              
          </Row>
          
      </Container>
    </div>
  )
}

export default LandingPage