import React from 'react'
import { db, auth } from '../firebase-config'
import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import Header from './Header'
import moment from 'moment'
import { collection, doc, getDoc, updateDoc, deleteDoc, Timestamp } from "firebase/firestore"
import { useNavigate, useParams } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import imgCancel from '../images/cancel.png'
import imgSave from '../images/save.png'
import imgDelete from '../images/delete.png'

const UpdateServiceRecord = () => {

    const [ record, setRecord ] = useState("");
    const { recordid } =  useParams();
    const singleRecordCollectionRef = collection(db, "servicerecords")
    const navigate = useNavigate();
    const [ newVID, setNewVID ] = useState("");
    const { user } = UserAuth()

    useEffect(()  => {

      const loadRecord = async () => {
        const recordRef = doc(singleRecordCollectionRef, recordid)
        const unsub = await getDoc(recordRef)
            .then((doc) => {
            setRecord(doc.data());
            document.getElementById("newVID").value = doc.data().vID
            document.getElementById("newOdometer").value = doc.data().odometer
            document.getElementById("newServiceDate").value = moment(doc.data().servicedate.toDate()).format("YYYY-MM-DD 00:00:00")
            document.getElementById("newCategory").value = doc.data().category
            document.getElementById("newLocation").value = doc.data().location
            document.getElementById("newDescription").value = doc.data().description
            document.getElementById("newCost").value = doc.data().cost
            })
            
            return () => {
            unsub();
            }
      }

      loadRecord();

    }, [])

    

    const delRecord = async (recordid) => {
      
      if (window.confirm("Are you sure you wish to delete service record: " + recordid + "?")) {
        const recordDoc = doc(singleRecordCollectionRef, recordid);
        await deleteDoc( recordDoc )
        alert(recordid + " deleted successfully.")  
      } else {
        alert(recordid + " NOT deleted.")
      }

      navigate(-1)
    
    }

    const updateRecord = async (event) => {
        event.preventDefault()
        var newVID = document.getElementById("newVID").value
        var newOdometer = document.getElementById("newOdometer").value
        var newServiceDate = document.getElementById("newServiceDate").value
        var newCategory = document.getElementById("newCategory").value
        var newLocation = document.getElementById("newLocation").value
        var newDescription = document.getElementById("newDescription").value
        var newCost = document.getElementById("newCost").value

        const updatedChanges = {
            vID: newVID,
            odometer: Number(newOdometer),
            servicedate: Timestamp.fromDate(new Date(newServiceDate)),
            category: newCategory,
            location: newLocation,
            description: newDescription,
            cost: newCost
        }

        const recordRef = doc(singleRecordCollectionRef, recordid)
        await updateDoc(recordRef, updatedChanges)
        navigate(-1)
    }
    
  
    return (
    <div className='m-1'>
      <Header />

      <Container className='panel col-sm-12 col-md-12 col-lg-12 mx-auto'>

          <Row>
            <Col>
              <div className='d-flex justify-content-between'>
                <h1>Update Service Record</h1>
                <a onClick={() => delRecord(recordid)}><img className="icons" src={imgDelete} /></a>

              </div>
              <hr />
              
              <form className='vehicleDetails'>

                <p className='m-0'><span>vID</span></p>
                <p><input className='p-1' id='newVID' readOnly /></p>
                
                <p className='m-0'><span>Odometer</span></p>
                <p><input className='p-1' id='newOdometer'  /></p>
                
                <p className='m-0'><span>Service Date</span></p>
                <p><input className='p-1' id='newServiceDate' /></p>

                <p className='m-0'><span>Category</span></p>
                <p><input className='p-1' id='newCategory' /></p>

                <p className='m-0'><span>Location</span></p>
                <p><input className='p-1' id='newLocation' /></p>
                
                <p className='m-0'><span>Description</span></p>
                <p><input className='p-1' id='newDescription' /></p>
                
                
                
                <p className='m-0'><span>Cost</span></p>
                <p><input className='p-1' id='newCost' /></p>
                
              </form>
        
            </Col>
            <div className='d-flex justify-content-end mt-3'>
              <a onClick={() => navigate(-1)}><img className="icons" src={imgCancel} /></a>
              <a onClick={updateRecord}><img className="icons ms-3" src={imgSave} /></a> 
            </div>
          </Row>

      </Container>
    
    </div>
  )
}

export default UpdateServiceRecord