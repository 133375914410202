import React from 'react';
import { useState, useEffect } from "react"; 
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Header from './Header';
import { db, auth } from "../firebase-config";
import { UserAuth } from '../context/AuthContext'
import imgCancel from '../images/cancel.png'
import imgSave from '../images/save.png'

const AddServiceRecord = () => {
    const [vID, setVID] = useState("");
    const [servicedate, setServiceDate] = useState("");
    const [category, setCategory] = useState("");
    const [odometer, setOdometer] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [cost, setCost] = useState("");
    const navigate = useNavigate();
    const { vid } =  useParams();
    const serviceRecsCollRef = collection(db, "servicerecords");
    const { user } = UserAuth()
    
    useEffect(()  => {

    
    }, [])    

    const addServiceRecord = async (event) => {
        event.preventDefault()
        
        const holdDate = servicedate + " " + "00:00:00";
        const newRecord = {
            vID: vid,
            useremail: `${user?.email}`,
            servicedate: Timestamp.fromDate(new Date(holdDate)),
            category: category,
            odometer: Number(odometer),
            location: location,
            description: description,
            cost: cost
        }
    
        await addDoc(serviceRecsCollRef, newRecord)
        
        navigate(-1)
        // clearFields();
      
    }

    // The code below is shortcuts I created to help data entry
    // Handle Vehicle ID
    const handleAltimaClick = () => {
        setVID("03");
        document.getElementById("vID").value ="03";
    }

    const handle17JeepClick = () => {
        setVID("06");
        document.getElementById("vID").value ="06";
    }

    const handleInfinitiClick = () => {
        setVID("10");
        document.getElementById("vID").value ="10";
    }






    // Handle Category
    const handleAccessoriesClick = () => {
        setCategory("Accessories")
        document.getElementById("category").value ="Accessories"
    }

    const handleServiceClick = () => {
        setCategory("Service")
        document.getElementById("category").value ="Service"
    }




    // Handle Location
    const handleDiscountTireClick = () => {
        setLocation("Discount Tire")
        document.getElementById("location").value ="Discount Tire"
    }

    const handleAutoSolutionsClick = () => {
        setLocation("Auto Solutions")
        document.getElementById("location").value ="Auto Solutions"
    }

    const handleDaytonaDodgeClick = () => {
        setLocation("Daytona Dodge")
        document.getElementById("location").value ="Daytona Dodge"
    }

    const handleDaytonaNissanClick = () => {
        setLocation("Daytona Nissan")
        document.getElementById("location").value ="Daytona Nissan"
    }

    const handleDaytonaInfinitiClick = () => {
        setLocation("Daytona Infiniti")
        document.getElementById("location").value ="Daytona Infiniti"
    }

    const handleDestinationClick = () => {
        setLocation("Destination 4x4")
        document.getElementById("location").value ="Destination 4x4"
    }

    const handleAmazonClick = () => {
        setLocation("Amazon")
        document.getElementById("location").value ="Amazon"
    }

    const handleQuadratecClick = () => {
        setLocation("Quadratec")
        document.getElementById("location").value ="Quadratec"
    }



    // Handle Task
    const handleOilChangeClick = () => {
        setDescription("Oil/filter change")
        document.getElementById("description").value ="Oil/filter change"
    }

    const handleBalanceRotateClick = () => {
        setDescription("Balance and rotate tires")
        document.getElementById("description").value ="Balance and rotate tires"
    }

    return (
        <div className='m-1'>
            <Header />
            <div className="panel col-sm-12 col-md-8 col-lg-6 mx-auto">
                {/* <div className='shortcutMenu'>
                    <div className='menu'>
                        <h1>Vehicles</h1>
                        <hr />
                        <button onClick={handleAltimaClick}>Altima</button>
                        <button onClick={handle17JeepClick} >2017 Jeep</button>
                        <button onClick={handleInfinitiClick} >Infiniti Q50</button>
                    </div>

                    <div className='menu'>
                        <h3>Category</h3>
                        <button onClick={handleAccessoriesClick}>Accessories</button>
                        <button onClick={handleServiceClick} >Service</button>
                    </div>

                    <div className='menu'>
                        <h3>Location</h3>
                        <button onClick={handleAutoSolutionsClick} >Auto Solutions</button>
                        <button onClick={handleDaytonaDodgeClick} >Daytona Dodge</button> 
                        <button onClick={handleDaytonaNissanClick} >Daytona Nissan</button>
                        <button onClick={handleDaytonaInfinitiClick} >Daytona Infiniti</button>
                        <button onClick={handleDestinationClick} >Destination 4x4</button>
                        <button onClick={handleDiscountTireClick}>Discount Tire</button>
                        <button onClick={handleAmazonClick}>Amazon</button>
                        <button onClick={handleQuadratecClick}>Quadratec</button>
                    </div>

                    <div className='menu'>
                        <h3>Tasks</h3>
                        <button onClick={handleOilChangeClick}>Oil/filter change</button>
                        <button onClick={handleBalanceRotateClick} >Balance and rotate tires</button>
                    </div>
                </div> */}
                <div className=""> {/* start of add service record panel */}
                    <h1>Add Service Record</h1>  
                    <hr />
                    <form className='d-flex flex-column'>
                        <input className='m-1 p-2' type="text" id="vID" placeholder={vid} readOnly />
                        <input className='m-1 p-2' type="text" id="servicedate" placeholder="Service Date 2000-01-01" required onChange={(event) => {moment(setServiceDate(event.target.value)).toDate()}} />
                        <input className='m-1 p-2' type="text" id="category" placeholder="Category: Accessories, Purchase or Service" required onChange={(event) => {setCategory(event.target.value)}} />
                        <input className='m-1 p-2' type="number" id="odometer" placeholder="Odometer" required onChange={(event) => {setOdometer(event.target.value)}} />
                        <input className='m-1 p-2' type="text" id="location" placeholder="Location" required onChange={(event) => {setLocation(event.target.value)}} />
                        <input className='m-1 p-2' type="text" id="description" placeholder="Description" required onChange={(event) => {setDescription(event.target.value)}} />
                        <input className='m-1 p-2' type="text" id="cost" placeholder="Cost" required onChange={(event) => {setCost(event.target.value)}} />
                        <hr />
                        <div className='d-flex justify-content-end'>
                            <a onClick={() => navigate(-1)}><img className="icons" src={imgCancel} /></a>
                        <a onClick={addServiceRecord}><img className="icons ms-3" src={imgSave} /></a> 
                        </div>
                        
                    </form>
                </div> {/* end of add service record panel */}
            </div>
        
        </div>
    )
}

export default AddServiceRecord