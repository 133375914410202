import React from 'react'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'

import imgSignup from '../images/getstarted01.png'
import imgSignupScreen from '../images/getstarted02.png'
import imgVLIcons from '../images/gs-vl-icons.png'
import imgVLAddVehicle from  '../images/gs-vl-addVehicle.png'
import imgVDUpdateDetails from '../images/gs-vd-updateDetails.png'
import imgVDUpdateExample from '../images/gs-vd-updateExample.png'
import imgVDViewServiceRecords from '../images/gs-vd-viewServiceRecords.png'
import imgSRPart1 from '../images/gs-sr-part1.png'
import imgSRPart2 from '../images/gs-sr-part2.png'
import imgSRAddSR1 from '../images/gs-sr-addServiceRecords1.png'
import imgSRAddSR2 from '../images/gs-sr-addServiceRecords2.png'
import imgSRAddSRInput from '../images/gs-sr-addServiceRecordsInput.png'
import imgSRUpdate1 from '../images/gs-sr-updateServiceRecord1.png'
import imgSRUpdate2 from '../images/gs-sr-updateServiceRecord2.png'

import add from '../images/add.png'
import view from '../images/view.png'
import cancel from '../images/cancel.png'
import del from '../images/delete.png'
import save from '../images/save.png'
import exit from '../images/exit.png'



import { useNavigate } from "react-router-dom"
import imgExit from '../images/exit.png'

const Help = () => {
    const navigate = useNavigate();

    return (
        <Container className='panel col-sm-12 col-md-8 col-lg-6 mx-auto'>
            <Row className='xd-flex xflex-column xalign-items-center'>

                <Col>
                <h1>Hello and thank you for visiting My Garage!</h1>
                    <p>
                        MyGarage is a project I developed out of necessity. I was tired of saving paper and even though I scanned all of my receipts to PDF files, there was no easy way to use 
                        that data. I've tried multiple apps along the way, but for one reason or another, they just didn't fit with my preferred method of record keeping. Being a software developer in a previous 
                        life, I thought this would be a good challenge to take on.
                    </p>

                    <p>
                        The app is simple to use. You enter your vehicle details, upload an image, then you can begin adding service records to track. Each vehicle will contain its own set of service records. Records
                        can be service records, such as oil changes, tire rotate and balance, etc. I also use another category named accessories, which I use for things that I purchase for
                        each vehicle. For example, since I live in Florida, every vehicle I purchase gets a sunshade to protect the dash and keep the heat to a minimum. I add the record to the specific
                        vehicle and from that point forward, I have a history of everything that I put into each vehicle. Please understand what this app is NOT. This app will NOT remind you to do
                        anything. However, it will track any of the maintenance and accessories you purchase and enter into the app. At any given time, I can see what work I had performed, where, when,
                        and how much. I can also see what accessories I have purchased, and all from my mobile or desktop device. 
                    </p>
                    
                    <p>
                        This has already proven its value by saving me from an "out of warranty" experience with 
                        Nissan. Pulled up the app, showed the date, mileage and exactly what work they worked on, and THEN they realized the issue was on their end.
                    </p>

                    <p>
                        Using this web app, you will see several icons. The icons will do the following things:
                        <ul>
                            <li className='m-2'><img className='icons' src={add} alt='' /> - this icon represents "adding" a record</li>
                            <li className='m-2'><img className='icons' src={view} alt='' /> - this icon represents "viewing" records</li>
                            <li className='m-2'><img className='icons' src={cancel} alt='' /> - this icon represents "canceling" an update</li>
                            <li className='m-2'><img className='icons' src={del} alt='' /> - this icon represents "deleting" records</li>
                            <li className='m-2'><img className='icons' src={save} alt='' /> - this icon represents "saving" records</li>
                            <li className='m-2'><img className='icons' src={exit} alt='' /> - this icon represents "exiting" records</li>
                        </ul>
                        
                    </p>

                    <hr />
                    
                        <h1>Using My Garage</h1>
                        <ul>
                            <li><a href='#loginsignup'>Login/Signup</a></li>
                            <li><a href='#vehiclelist'>Using the Vehicle List</a></li>
                            <li><a href='#addvehicle'>Adding new vehicles to the garage</a></li>
                            <li><a href='#vehicledetails'>Viewing vehicle details</a></li>
                            <li><a href='#vehicledetails'>Update/edit vehicle details</a></li>
                            <li><a href='#servicerecords'>View service records</a></li>
                            <li><a href='#addservicerecords'>Add service records</a></li>
                            <li><a href='#updateservicerecords'>Update/edit/delete service records</a></li>
                        </ul>

                        <hr />
                        <h1 id='loginsignup'>Getting started - Signup</h1>

                        <div>
                            <p>Before you can login to My Garage, you will have to create a user account. Do this by clicking "Signup" on the main login page.</p>
                            <p><img style={{width: "100%"}} src={imgSignup} alt='login/signup example' /></p>
                            <p>Clicking "Signup" will take you to the signup screen. Enter your email address and password, then click "Signup"</p>
                            <p><img style={{width: "100%"}} src={imgSignupScreen} alt='login/signup example' /></p>                        
                        </div>

                        <hr />
                        <h1 id='vehiclelist'>Getting started - Vehicle List</h1>

                        <div>
                            <p>The "Vehicle List' is the main screen on My Garage. From here you can view vehicle details, add new vehicles, and add service records.</p>
                            <p><img style={{width: "100%"}} src={imgVLIcons} alt='vehicle list example' /></p>
                            <h3>Icon Definitions</h3>
                            <ul>
                                <li>1 - This is used to view all vehicles, even inactive ones. If you do not have any inactive vehicles, this will do nothing</li>
                                <li>2 - This is the add vehicle icon. Click this to add new vehicles. Put hyperlink here to add new vehicle section</li>
                                <li>3 - Shortcut to add new service records for this specific vehicle</li>
                            </ul>
                            <p>Since icon labeled "1" will not do anything without deactivated vehicles, we'll go ahead and skip to "2", add vehicle</p>
                            <p id='addvehicle'><img style={{width: "100%"}} src={imgVLAddVehicle} alt='add new vehicle example' /></p>                       

                            <p>Icon #3 is a shortcut from the Vehicle List screen to add service records. Clicking this icon will take you to the Add Service Records screen. More on that later.</p>
                        </div>

                        <hr />
                        <h1 id='vehicledetails'>Getting started - Vehicle Details</h1>

                        <div>
                            <p>The "Vehicle Details' area is where you can update/edit vehicle details and view/add/delete service records. Let's take a look at how we accomplish these tasks.</p>
                            <p>First, let's focus on the vehicle details. If we need to update/edit the vehicle details section, simply click the vehicle details or click the edit icon. They are both
                                highlighted in red below for identification.
                            </p>
                            <p><img style={{width: "100%"}} src={imgVDUpdateDetails} alt='vehicle list example' /></p>
                            
                            <p>This is what the update/edit vehicle details screen looks like. Here you have three options, cancel, save or exit</p>
                                
                            <p><img style={{width: "100%"}} src={imgVDUpdateExample} alt='update vehicle details example' /></p>                       
                            <ul>
                                <li>Cancel will not make any changes and return you to the vehicle details page</li>
                                <li>Save will save any changes and return to the vehicle details page</li>
                                <li>Exit will function as cancel. It will not save any changes and return to the vehicle details page</li>
                            </ul>
                            <hr />
                            <h1 id='servicerecords'>View Service Records</h1>
                            <p>To view the service records for the selected vehicle, click the view icon shown below highlighted in red. This will populate the Service Records data panel with the proper data.</p>
                            <p><img style={{width: "100%"}} src={imgVDViewServiceRecords} alt='update vehicle details example' /></p>     
                            <p>Service records shown below</p>
                            <p><img style={{width: "100%"}} src={imgSRPart1} alt='part one of service record example' /></p>     
                            <p>as you can see, the bottom of each vehicle's service record panel shows a running total for that vehicle.</p>
                            <p><img style={{width: "100%"}} src={imgSRPart2} alt='part two of service record example' /></p>     

                            <hr />
                            <h1 id='addservicerecords'>Adding Service Records</h1>
                            <p>Adding service records can be accomplished from two areas. The first area (#1) is from the vehicle list and the second is from the service records area (#2). See images below. </p>
                            <p><img style={{width: "100%"}} src={imgSRAddSR1} alt='part one of add service record example' /></p>     
                            <p><img style={{width: "100%"}} src={imgSRAddSR2} alt='part two of add service record example' /></p>     
                            
                            <p>This is the add service records screen. Here you will input all of the data for the record. Once you are finished, you will have the option to cancel or save the record</p>
                            <p><img style={{width: "100%"}} src={imgSRAddSRInput} alt='add service record input example' /></p>

                            <hr />
                            <h1 id='updateservicerecords'>Update/Edit Service Records</h1>
                            <p>There may come a time where you need to update a service record. It may be to correct a typo or something like adding additional detail. In order to update/edit a service record, just click that service record as show below</p>
                            <p><img style={{width: "100%"}} src={imgSRUpdate1} alt='update service record input example' /></p>
                            <p>Clicking a service record will allow full access to the fields for updating as shown below. The vehicleID is read-only and unable to be changed.</p>
                            <p>This is also where you could delete a service record if desired.</p>
                            <p><img style={{width: "100%"}} src={imgSRUpdate2} alt='update service record input example' /></p>

                        </div>
                        <hr />
                        <div className='d-flex justify-content-end'>
                            <a onClick={() => navigate('/vehicles')}><img className="icons ms-2" src={imgExit} /></a> 
                        </div>

                </Col>
                
            </Row>
        </Container>
    )
}

export default Help