import React from 'react'

const Nopage = () => {
  return (
    <div>
        Error 404: No page found!
    </div>
  )
}

export default Nopage