import React from 'react'
import { useState } from 'react'
import { Image, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext'
import logo from '../images/mmg_logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';

const Signup = () => {
    const [signupEmail, setSignupEmail] = useState("");
    const [signupPassword, setSignupPassword] = useState("");
    const [signupPasswordConfirm, setSignupPasswordConfirm] = useState("");

    const navigate = useNavigate();

    const { user, userSignup } = UserAuth()
    
    const signup = async () => {

        try {
          if (signupPassword !== signupPasswordConfirm) {
            alert("Passwords do not match!");
            window.location.reload();
            return null;
          } else {
            // userSignup called from AuthContext
            await userSignup(signupEmail, signupPassword)
            // console.log(user);
            alert("new user created successfully");
            navigate("/vehicles");
          }
        
        } catch (error) {
          alert(error.message);
        }
        
  
    }
    
    return (
      <div className='center d-flex justify-content-center align-items-center'>
        <div className='xpanel xcol-sm-12 xcol-md-8 xcol-lg-4 xmx-auto'>
            <div className="m-3 shadow xp-3 mb-5 bg-white rounded">
              <Image fluid className='logo mb-3' src={logo} /> 
              {/* <h1>Signup</h1> */}
              {/* <hr /> */}
              <Form className="mt-5 d-flex flex-column">
                  
                  <Form.Label className=""><strong>Email</strong></Form.Label>
                  <input
                    className="mb-3 p-2"
                    id="email" type="email"
                    required
                    onChange={(event) => {setSignupEmail(event.target.value)}}
                  />
                  
                  <Form.Label className=""><strong>Password</strong></Form.Label>
                  <input 
                    className="mb-3 p-2"
                    id="password"
                    type="password"
                    required
                    onChange={(event) => {setSignupPassword(event.target.value)}}
                  />
                  
                  <Form.Label className=""><strong>Confirm Password</strong></Form.Label>
                  <input
                    className='mb-5 p-2'
                    id="confirmpassword"
                    type="password"
                    required
                    onChange={(event) => {setSignupPasswordConfirm(event.target.value)}}
                  />
                  
                  <Button className="p-2" variant="primary" size="sm" onClick={signup}>Signup</Button>
              
              </Form>
            </div>
            
        </div>
      </div>
    )
}

export default Signup