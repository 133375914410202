import React from 'react'
import Header from './Header'
import { db, auth } from '../firebase-config'
import { onAuthStateChanged } from 'firebase/auth'
import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import moment from 'moment'
import { collection, doc, getDoc, onSnapshot, query, orderBy, where, FieldPath } from "firebase/firestore"
import { useNavigate, useParams } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import imgAdd from '../images/add.png'
import imgEdit from '../images/edit.png'
import imgExit from '../images/exit.png'
import imgView from '../images/view.png'


// This uses params for the firebase record id since we are pulling a single record, we need to use the firebase record id
// Therefore, vehicleid = the firebase record id, NOT the vID
const Vehicle = () => {
  const [vehicle, setVehicle] = useState("")
  const [serviceRecords, setServiceRecords] = useState([])
  const [vehicleID, setVehicleID] = useState("")
  const { vehicleid } =  useParams();
  let serviceTotal = 0;
  const singleVehicleCollectionRef = collection(db, "vehicleinfo")
  const vehicleServiceCollRef = collection(db, "servicerecords")
  const navigate = useNavigate();
  const { user } = UserAuth()
  
  useEffect(()  => {
    
    const loadVehicle = async () => {
      const vehicleRef = doc(singleVehicleCollectionRef, vehicleid)
      const unsub = await getDoc(vehicleRef)
        .then((doc) => {
          setVehicle(doc?.data());
          loadServiceRecords(doc?.data().vID);
        })

        return () => {
          unsub();
        }
  
      }

    loadVehicle();
    
  }, [])

  

  const loadServiceRecords = (vid) => {
    // alert("made it to loading service records with vID: " + vid)
    // console.log("vID: " + vid + `${user?.email}`)
    // alert("Inside Load Service Records: " );
    const unsub = onSnapshot(query(vehicleServiceCollRef, orderBy("odometer", "desc"), where("vID", "==", vid), where("useremail", "==", `${user?.email}`)), (snapShot) => {
    
    // const unsub = onSnapshot(query(vehicleServiceCollRef, where("vID", "==", vid)), (snapShot) => {

      try {
        let list = [];
        snapShot.docs.forEach(doc=>{
          list.push({id:doc.id, ...doc.data()});
        });
        setServiceRecords(list);
        // console.log(serviceRecords)
        // console.log("Total: " + serviceTotal)
        
      } catch (error) {
        console.log(error);
      }
    });
    
    return () => {
      unsub();
    }
  }
  
  


  return (
    <div id='top' className=''>
      <Header />

      <Container className='panel col-sm-12 col-md-8 xcol-lg-6 mx-auto'>


          <Row>
            <Col>
              <h1>Vehicle Details</h1>
              <hr />
              
              <h2 className='m-0'>{vehicle.year} {vehicle.make} {vehicle.model}</h2>
              <p className='m-0 mb-3' style={{fontSize: ".75rem"}}>vID: {vehicle.vID}</p>
              <div className='d-flex singleVehicleLayout col-sm-12 col-md-12 col-lg-12'>
                <div className='svlImg'>
                  {/* <img className='w-100' src={'/images/' + `${user.email}` + '/' + vehicle.vID + ".jpg"} /> */}
                  <img className='vehicleImg' src={vehicle?.picID} />
                </div>
                <div className='vehicleDetails'>
                <a onClick={() => navigate('/updatevehicle/' + vehicleid)}>
                  
                  <p className='m-0'><span>Color</span></p>
                  <p>{vehicle.color}</p>
                  
                  <p className='m-0'><span>Engine</span></p>
                  <p>{vehicle.engine}</p>

                  <p className='m-0'><span>Transmission</span></p>
                  <p>{vehicle.transmission}</p>
                  
                  <p className='m-0'><span>Tire Size</span></p>
                  <p>{vehicle.tiresize}</p>
                  
                  <p className='m-0'><span>Purchase Date</span></p>
                  <p>{moment(vehicle.purchasedate?.toDate()).format("YYYY-MM-DD")}</p>
                  
                  <p className='m-0'><span>Purchase Location</span></p>
                  <p>{vehicle.purchaselocation}</p>
                  
                  <p className='m-0'><span>Purchase Mileage</span></p>
                  <p>{Number(vehicle.purchasemileage).toLocaleString()}</p>
                  
                  <p className='m-0'><span>Purchase Amount</span></p>
                  <p>${Number(vehicle.purchaseamount).toLocaleString()}</p>
                </a>
                </div>
              </div>
            </Col>
            <div className='d-flex justify-content-end mt-3'>
              {/* <a onClick={() => loadServiceRecords(vehicle.vID)}><img className="icons" src={imgView} /></a> */}
              <a onClick={() => navigate('/updatevehicle/' + vehicleid)}><img className="icons ms-2" src={imgEdit} /></a> 
              <a onClick={() => navigate('/vehicles')}><img className="icons ms-2" src={imgExit} /></a> 
            </div>
          </Row>
          </Container>

          <Container className='panel col-sm-12 col-md-8 xcol-lg-6 mx-auto'>

            <Row>
              <Col>
                <div className='d-flex justify-content-between'>
                  <h1>Vehicle Records</h1>
                  <a onClick={() => navigate("/addservicerecord/" + vehicle.vID)}><img className="icons" src={imgAdd} /></a>
                </div>
                <hr />
                {serviceRecords.map((serviceRecord) => {
                  serviceTotal += Number(serviceRecord.cost);
                  return(
                    <a onClick={() => navigate("/updateservicerecord/" + serviceRecord.id)}>
                      <div className='vehicleDetails mb-2'>
                        
                        <div className='d-flex justify-content-between'>
                          <div>
                            <p className='m-0'><span>vID</span></p>
                            <p>{serviceRecord.vID}</p>
                          </div>
                          
                          <div>
                            <p className='m-0'><span>Odometer</span></p>
                            <p>{Number(serviceRecord.odometer).toLocaleString()}</p>
                          </div>

                          <div>
                            <p className='m-0 text-end'><span>Service Date</span></p>
                            <p>{moment(serviceRecord.servicedate?.toDate()).format("YYYY-MM-DD")}</p>
                          </div>
                        </div>
                        
                        <div className='d-flex justify-content-between'>
                          
                          </div>  
                          {/* <div> */}
                            <p className='m-0'><span>Location</span></p>
                            <p>{serviceRecord.location}</p>

                            <p className='m-0'><span>Description</span></p>
                            <p>{serviceRecord.description}</p>
                          {/* </div> */}
                        
                        
                        <div className='d-flex justify-content-between'>
                          
                          <div>
                            <p className='m-0'><span>Category</span></p>
                            <p>{serviceRecord.category}</p>
                          </div>  
                          
                          
                          <div>
                            <p className='m-0 text-end'><span>Cost</span></p>
                            <p>${Number(serviceRecord.cost).toFixed(2)}</p>
                          </div>
                        </div>
          
                      </div>
                    </a>  
                  )
                  
                })}
              </Col>  
              <div className='mt-3 text-end'>  
                  <h4><span>Service Total: </span>${Number(serviceTotal).toFixed(2)}</h4>
                  <a onClick={() => navigate('/vehicles')}><img className="icons ms-2" src={imgExit} /></a> 
              </div>
              
            </Row>
          </Container>
    </div>
  )
}

export default Vehicle