import React from 'react'
import { useState } from 'react'
import { Image, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext'
import logo from '../images/mmg_logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const navigate = useNavigate();

    const { user, userLogin } = UserAuth()
    
    const login = async () => {

        try {
          // userLogin called from AuthContext
            await userLogin(loginEmail, loginPassword)
            navigate("/vehicles");
        } catch (error) {
          alert(error.message);
        }
    }
    
    return (
      <div className='center d-flex justify-content-center align-items-center'>
        <div className='xpanel xcol-sm-12 xcol-md-8 xcol-lg-4'>

            <div className="m-3 shadow p-0 mb-5 bg-white rounded">
              <Image fluid className='logo mb-3' src={logo} /> 
              {/* <h1>My Moto Garage | Login</h1> */}
              {/* <hr /> */}
              <Form className="mt-5 d-flex flex-column">
                  
                  <Form.Label className=""><strong>Email</strong></Form.Label>
                  <input
                    className="mb-3 p-2"
                    id="email" type="email"
                    required
                    onChange={(event) => {setLoginEmail(event.target.value)}}
                  />
                  
                  <Form.Label className=""><strong>Password</strong></Form.Label>
                  <input 
                    className="mb-3 p-2"
                    id="password"
                    type="password"
                    required
                    onChange={(event) => {setLoginPassword(event.target.value)}}
                  />
                    
                  <Button className="p-2" variant="primary" size="sm" onClick={login}>Login</Button>
              
              </Form>
            </div>
            
        </div>
      </div>
    )
}

export default Login