import React from "react"
import Header from './pages/Header';
// import Home from "./pages/Home";
// import Content from './pages/Content';
import Footer from './pages/Footer';
import AddServiceRecord from './pages/AddServiceRecord';
import AddVehicle from './pages/AddVehicle';
import Nopage from "./pages/Nopage";
import { Routes, Route } from "react-router-dom";
import VehicleList from "./pages/VehicleList";
import Vehicle from "./pages/Vehicle";
import Help from "./pages/Help";
import Settings from "./pages/Settings";
// import ServiceRecordList from "./pages/ServiceRecordList";
import UpdateSettings from "./pages/UpdateSettings";
import UpdateVehicle from "./pages/UpdateVehicle";
import UpdateServiceRecord from "./pages/UpdateServiceRecord";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./pages/ProtectedRoute";
import LandingPage from "./pages/LandingPage";

function App() {
  return (
    <div className="">

      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/help" element={<Help />} />
          <Route path="*" element={<Nopage />} />
    
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/vehicles" element={<ProtectedRoute><VehicleList /></ProtectedRoute>} />
          <Route path="/:vehicleid" element={<ProtectedRoute><Vehicle /></ProtectedRoute>} />
          <Route path="/addvehicle" element={<ProtectedRoute><AddVehicle /></ProtectedRoute>} />
          <Route path="/addservicerecord/:vid" element={<ProtectedRoute><AddServiceRecord /></ProtectedRoute>} />
          <Route path="/updatesettings" element={<ProtectedRoute><UpdateSettings /></ProtectedRoute>} />
          <Route path="/updatevehicle/:vehicleid" element={<ProtectedRoute><UpdateVehicle /></ProtectedRoute>} />
          <Route path="/updateservicerecord/:recordid" element={<ProtectedRoute><UpdateServiceRecord /></ProtectedRoute>} />

        </Routes>  
      </AuthContextProvider>
      
    </div>
  );
}

export default App;
